<template>
    <div>
        <LayoutWithNav
            v-if="!checkDetailRoute"
            :options="routes"
            :selectedOption="selectedOption"
            :heading="'Quote Tracker'"
            :description="''"
            :type="!isNormalUser ? 'left' : 'center'"
            :haveExtraHeaderItem="!isNormalUser"
        >
            <template v-slot:header-extra>
                <div class="text-right header-actions">
                    <bev-search-input
                        :inputType="'text'"
                        :inputClass="'have-bg'"
                        :inputPlaceholder="'Search by Quote Number or Insured Name'"
                        class="search-input"
                        v-model="quoteSearch"
                    ></bev-search-input>
                </div>
            </template>
            <template v-slot:content>
                <router-view
                    :quoteSearch="quoteSearch"
                    :key="$route.path"
                ></router-view>
            </template>
        </LayoutWithNav>
        <router-view :key="$route.path" v-else></router-view>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutWithNav from "@/components/Layout/LayoutWithNav";
export default {
    components: {
        LayoutWithNav,
    },
    data() {
        return {
            quoteSearch: "",
            routes: [
                {
                    name: "Active",
                    clickHandler: this.clickHandler,
                    key: "1",
                    path: "active",
                },
                {
                    name: "Inactive",
                    clickHandler: this.clickHandler,
                    key: "2",
                    path: "inactive",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("auth", ["isNormalUser", "isUnderwriter"]),
        selectedOption() {
            const currentRoute = this.routes.find(
                (route) => `/quotes/${route.path}` === this.$route.path
            );

            return currentRoute.key;
        },
        checkDetailRoute() {
            return (
                this.$route.name === "QuoteDetail" ||
                this.$route.name === "QuotePayment"
            );
        },
    },
    methods: {
        /**
         *
         * @param {{ key:String,route:String }} option
         */
        clickHandler(option) {
            this.$router.push({ path: `/quotes/${option.path}` });
        },
    },
    watch: {
        $route: function () {
            this.quoteSearch = "";
        },
    },
};
</script>

<style scoped>
.search-input {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 50px;
}
.header-actions {
    margin: 0 20px;
    width: 400px;
    min-width: 400px;
    max-width: 50%;
}
@media (max-width: 1420px) {
    .header-actions {
        width: 100%;
        max-width: 100%;
    }
}
</style>
